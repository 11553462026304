import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import RegistrationPage from '@/components/RegisterPage.vue'
import VerificationPage from "@/components/VerificationPage.vue";
import WelcomePage from '@/components/WelcomePage.vue';
import CustomerDashboardPage from '@/components/CustomerDashboardPage.vue';
import AdminPage from "@/components/AdminCustomersPage.vue";
import PortalPage from "@/components/UserPortalPage.vue";
import CheckDashboardPage from "@/components/CheckDashboardPage.vue";
import ChecksCustomerPage from "@/components/CustomerChecksPage.vue";
import ChecksAdminPage from "@/components/AdminChecksPage.vue";
import UsersPage from "@/components/AdminUsersPage.vue";
import ForgotDashboardLinkPage from "@/components/ForgotDashboardLinkPage.vue";
import LoginPage from "@/components/LoginPage.vue";
import NotFoundPage from "@/components/NotFoundPage.vue";
import axios from "axios";
import store from './store'; // import the Vuex store
import { i18n } from './main';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
    },
    {
        path: '/',
        component: App,
        redirect: "register"
    },
    {
        path: '/register',
        name: 'register',
        component: RegistrationPage
    },
    {
        path: '/welcome',
        name: 'welcome',
        component: WelcomePage,
    },
    {
        path: '/verify/:hashValue',
        name: 'verify',
        component: VerificationPage,
        props: route => ({ hashValue: route.params.hashValue, lang: route.query.lang || 'de' })
    },
    {
        path: '/admin',
        name: 'admin',
        component: AdminPage
    },
    {
        path: '/portal',
        name: 'portal',
        component: PortalPage
    },
    {
        path: '/customer/:customerHash',
        name: 'customer',
        component: CustomerDashboardPage,
        props: route => ({ customerHash: route.params.customerHash})
    },
    {
        path: '/users',
        name: 'users',
        component: UsersPage
    },
    {
        path: '/customers/checks/:customerId',
        name: 'customerchecks',
        component: ChecksCustomerPage,
        props: route => ({ customerId: route.params.customerId})
    },
    {
        path: '/checks/:customerId',
        name: 'adminchecks',
        component: ChecksAdminPage,
        props: route => ({ customerId: route.params.customerId})
    },
    {
        path: '/check/:hashValue',
        name: 'check',
        component: CheckDashboardPage,
        props: route => ({ hashValue: route.params.hashValue})
    },
    {
        path: '/forgot',
        name: 'forgot',
        component: ForgotDashboardLinkPage,
    },
    {   // 404
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: NotFoundPage,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
export let previousRoute = null;

router.beforeEach((to, from, next) => {
    if (from.name) {
        previousRoute = from;
    }
    next();
});

// automatic redirect to login if response code is 401 or 403
// see: https://axios-http.com/docs/interceptors
axios.interceptors.response.use(function (response) {
    // status code 2xx
    return response;
}, function (error) {
    // any status code except 2xx
    if (error.response.status === 401) {
        router.push('/login');
    } else if (error.response.status === 403) {
        const errorMessage = i18n.global.t('unauthorized');
        // show an error via mutating the vuex store which will trigger the associated vie in the app.vue
        store.dispatch('showError', errorMessage).finally();
        router.push('/portal');
    }

    return Promise.reject(error);
});
