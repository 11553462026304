<template>
  <div>
    <div class="container py-5">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="logo-container">
            <div class="col-md-12 text-center">
              <router-link to="/register">
                <img src="../assets/logo.png" alt="Logo" class="logo-img">
              </router-link>
            </div>
          </div>
          <div class="col-md-12 text-center">
            <p class="welcome-text-bold">{{ $t('verificationText') }}</p>
          </div>

          <div class="col-md-12 text-center">
            <p class="contact">{{ $t('contact') }}<a href="mailto:support@checkfix.io">support@checkfix.io</a></p>
          </div>
          <div class="col-md-12 text-center">
            <img src="../assets/icon.png" alt="icon" class="logo-img">
          </div>

          <notification-box
              :message="notificationMessage"
              :is-success="isSuccess"
              :is-error="isError"
              :duration="5000"
              :is-visible="isVisible"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
html {
  position: relative;
  min-height: 100%;
}

.flex {
  display: flex;
  flex-direction: row;
}

body {
  margin: 0;
  padding-bottom: 80px; /* Height of the footer */
}

.logo-img {
  max-height: 100px;
  max-width: 100%;
}

.contact {
  padding-top: 30px;
  padding-bottom: 20px;
  white-space: pre-line;
  font-size: 20px;
}

.domain-table {
  color: #a6bd24;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.welcome-text {
  padding-top: 10px;
  white-space: pre-line;
  font-size: 20px;
}

.welcome-text-bold {
  white-space: pre-line;
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 768px) {
  .welcome-text {
    font-size: 14px; /* Adjust the font size as needed */
  }
}
</style>
<script>
import axios from "axios";
import NotificationBox from "@/components/NotificationBox.vue";
import {mapState} from "vuex";

export default {
  components: {NotificationBox},
  data() {
    return {
      resetFlag: false,
      notificationMessage: '',
      hash: '',
      domain: '',
      emailError: 'OK',
      domains: [],
      isSuccess: true,
      isError: false,
      isVisible: true,
      verificationText: this.$t('verificationText'),
    };
  },
  created() {
    let lang = this.$route.query.lang || this.selectedLanguage;
    if ((lang === '' || lang.length === 0 || lang === 'undefined') && (lang !== 'de' || lang !== 'en')) {
      lang = 'de';
    }
    this.$i18n.locale = lang;

    this.hash = this.$route.params.hashValue;

    const url = process.env.VUE_APP_SERVER_URL + '/api/v1/verify/' + this.hash + "?lang=" + lang;
    this.verifyEmail(url);
  },
  methods: {
    async verifyEmail(url) {
      axios.get(url)
          .then(response => {
            this.notificationMessage = response.data;
            this.isVisible = true;
            this.isError = false;
            this.isSuccess = true;
          })
          .catch((error) => {
                this.notificationMessage = error.response.data;
                this.isVisible = true;
                this.isError = true;
                this.isSuccess = false;
              }
          );
    },
    clearNotification() {
      this.notificationMessage = '';
      this.isSuccess = false;
      this.isError = false;
      this.emailError = 'OK';
    },
    validateDomainInput() {
      const index = this.domain.indexOf('.');
      return this.domain.length > 0 && !this.domain.includes('@') && ((this.domain.match(/\./g) || []).length === 1) && (this.domain.substring(index)).length > 2;
    },
    resetForm() {
      this.resetFlag = true;
      this.domain = '';
      this.emailError = 'OK';
      this.showEmailError = false;
    },
    addDomain() {
      this.clearNotification();
      this.resetFlag = false;
    },
  },
  computed: {
    isDomainValid() {
      return this.validateDomainInput();
    },
    ...mapState(['selectedLanguage'])
  },
};
</script>
