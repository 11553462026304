<template>
  <div class="container-fluid mx-auto">
    <h1 v-if="this.checks && this.checks.customer" class="row d-flex justify-content-center">{{ $t('fileList') }}
      {{ extractDomain(this.checks.customer.email) }}</h1>

    <button class="btn btn-primary btn-sm float-right add-check-button"
            @click="createCheck(this.checks.customer)"
            v-html="$t('addCheck')"></button>

    <div class="table-responsive table-responsive-sm table-responsive-md">
      <table class="table">
        <thead>
        <tr>
          <th v-html="$t('id')"></th>
          <th v-html="$t('date')"></th>
          <th v-html="$t('checkType')"></th>
          <th v-html="$t('checkShared')"></th>
          <th v-html="$t('action')"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="check in checks.checkData" :key="check.id">
          <td>{{ check.check.id }}</td>
          <td>{{ formatTimestamp(check.check.createdTimestamp) }}</td>
          <td>
            <span v-if="check.hasHackTest">Hack-Test</span>
            <span v-else>Status-Check</span>
          </td>
          <td>
            <span v-if="check.check.sent">&#x2714;</span>
            <span v-else>&#x2718;</span>
          </td>
          <td>
            <div class="button-container">
              <div class="d-flex flex-wrap justify-content-center">
                <button class="btn btn-primary btn-sm mt-0 mr-1 button-small-height"
                        @click="openFileUploadModal(check)"
                        v-html="$t('uploadFiles')"></button>
                <button class="btn btn-primary btn-sm mr-1 button-small-height"
                        @click="showCheck(check)"><span>{{this.$t('show')}}</span></button>
                <button class="btn btn-primary btn-sm mr-1 button-small-height"
                        @click="informCheckReady(check)"><span>{{this.$t('share')}}</span></button>
                <button class="btn btn-danger btn-block btn-sm button-small-height icon-button"
                        @click="deleteCheck(check)"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <modal v-if="showFileUpload" @close="closeFileUploadModal">
      <template #header>
        <h2 v-html="$t('uploadFiles')"></h2>
      </template>

      <template #body>
        <div class="container">
          <div class="row mb-2">
            <div class="col-3">
              <label for="fileTypeSelect">{{ $t('selectFileType') }}</label>
            </div>
            <div class="col-9">
              <select id="fileTypeSelect" v-model="selectedFileType">
                <option selected value="statusCheck">CheckFix StatusCheck</option>
                <option value="hackTest">CheckFix HackTest</option>
                <option value="overallScore">Overall Score</option>
                <option value="todoOverview">Todo Overview</option>
                <option value="coverImage">Cover Image</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div hidden>
              <label for="fileInput">{{ $t('selectFile') }}</label>
            </div>
            <input type="file" id="fileInput" ref="fileInput" @change="handleFileUpload()">
          </div>
          <div class="row mb-2">
            <button class="btn btn-primary btn-block uploadButton" @click="uploadFile(selectedFileType)"
                    v-html="$t('uploadFile')" :disabled="!selectedFile"></button>
          </div>
          <div class="row">
            <div v-if="checkparts && checkparts.length > 0">
              <h2 class="file-type-title">{{ $t('uploadedFiles') }}</h2>
              <div class="file-type-list">
                <p v-for="checkpart in checkparts" :key="checkpart.fileType">
                  <a href="#" class="file-type-link"
                     @click="handleFileTypeClick(checkpart.fileType)">{{ getFileTypeText(checkpart.fileType) }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <button class="btn btn-outline-secondary btn-block" @click="closeFileUploadModal" v-html="$t('close')"></button>
      </template>
    </modal>

    <notification-box
        :message="notificationMessage"
        :is-success="isSuccess"
        :is-error="isError"
        :duration="5000"
        :is-visible="isVisible"
    />
  </div>
</template>

<script>
import axios from 'axios';
import NotificationBox from "@/components/NotificationBox.vue";
import {mapState} from "vuex";
import moment from 'moment';
import Modal from "@/components/ModalBox.vue";

export default {
  components: {
    NotificationBox,
    Modal
  },
  data() {
    return {
      checks: [],
      selectedCheck: null,
      notificationMessage: '',
      isSuccess: false,
      isError: false,
      isVisible: false,
      showFileUpload: false,
      selectedFileType: "statusCheck",
      selectedFile: null,
      checkparts: null,
      showSendEmail: false,
    };
  },
  created() {
    let lang = this.$route.query.lang || this.selectedLanguage;
    if ((lang === '' || lang.length === 0 || lang === 'undefined') && (lang !== 'de' || lang !== 'en')) {
      lang = 'de';
    }
    this.$i18n.locale = lang;

    this.fetchChecks();
  },
  methods: {
    fetchChecks() {
      const customerId = this.$route.params.customerId;
      const url = process.env.VUE_APP_SERVER_URL + '/api/v1/customers/checks/' + customerId;
      axios.get(url)
          .then((response) => {
            this.checks = response.data;
            this.isSuccess = true;
            this.isError = false;
            this.isVisible = true;
          }).catch(error => {
            this.notificationMessage = error.response.data;
            this.isError = true;
            this.isVisible = true;
            this.isSuccess = false;
          })
    },
    formatTimestamp(timestamp) {
      return moment(timestamp).format('DD.MM.YYYY HH:mm');
    },
    openFileUploadModal(check) {
      this.selectedCheck = check;
      this.showFileUpload = true;
      this.fetchCheckParts()
    },
    closeFileUploadModal() {
      this.showFileUpload = false;
      this.selectedCheck = null;
      this.selectedFileType = "statusCheck";
    },
    handleFileUpload() {

      let allowedFileTypes = [];

      if (this.selectedFileType === "statusCheck" || this.selectedFileType === "hackTest") {
        allowedFileTypes = ['application/pdf'];
      } else if (this.selectedFileType === "coverImage" || this.selectedFileType === "overallScore" || this.selectedFileType === "todoOverview") {
        allowedFileTypes = ['image/jpeg', 'image/png'];
      }

      const fileList = event.target.files;

      if (fileList.length > 0) {
        const file = fileList[0];
        if (file !== null && allowedFileTypes.length > 0 && allowedFileTypes.includes(file.type)) {
          this.selectedFile = file;
          this.isError = false;
        } else {
          this.notificationMessage = "Unsupported File Type!"
          this.isError = true;
          this.isVisible = true;
        }
      }
    },
    uploadFile(type) {
      const formData = new FormData();
      formData.append('file', this.selectedFile)
      formData.append('checkId', this.selectedCheck.check.id);
      formData.append('customerId', this.selectedCheck.check.customerId);
      formData.append('type', type);

      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/checks/upload', formData, {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(response => {
        this.notificationMessage = response.data
        this.selectedFile = null;
        this.$refs.fileInput.value = null;
        this.selectedFileType = "statusCheck"
        this.isSuccess = true;
        this.isError = false;

        this.fetchCheckParts();
      }).catch(error => {
        this.selectedFile = null;
        this.$refs.fileInput.value = null;
        this.selectedFileType = "statusCheck"
        this.isSuccess = false;
        this.isError = true;
        this.notificationMessage = error.response.data
      });
    },
    fetchCheckParts() {
      axios.get(process.env.VUE_APP_SERVER_URL + '/api/v1/customers/checks/parts/' + this.selectedCheck.check.id)
          .then((response) => {
            this.checkparts = response.data
          })
          .catch(error => {
            // Show error notification message
            this.notificationMessage = error.response.data;
            this.isError = true;
            this.isVisible = true; // Set isVisible to true before showing the notification box
          });
    },
     handleFileTypeClick(fileType) {
      axios.get(process.env.VUE_APP_SERVER_URL + '/api/v1/checks/download/customer/' + this.selectedCheck.check.id + '/' + fileType, {responseType: 'arraybuffer'})
          .then(response => {
            // Create a Blob object from the response data
            let blob

            if (fileType === "statusCheck" || fileType === "hackTest") {
              blob = new Blob([response.data], {type: 'application/pdf'})
            } else {
              blob = new Blob([response.data], {type: 'image/png'})
            }

            // Create a download link and click it to start the download
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url

            let fileName = "CheckFix_HackTest.pdf"

            if (fileType === "statusCheck") {
              fileName = "CheckFix_StatusCheck.pdf"
            } else if (fileType === "coverImage") {
              fileName = "CoverImage.png"
            } else if (fileType === "overallScore") {
              fileName = "OverallScore.png"
            } else if (fileType === "todoOverview") {
              fileName = "TodoOverview.png"
            }

            link.download = fileName
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          });
    },

    getFileTypeText(fileType) {
      // Perform logic to determine the text based on the fileType
      if (fileType === 'statusCheck') {
        return 'CheckFix StatusCheck';
      } else if (fileType === 'hackTest') {
        return 'CheckFix HackTest';
      } else if (fileType === 'overallScore') {
        return 'Overall Score';
      } else if (fileType === 'todoOverview') {
        return 'Todo Overview';
      } else if (fileType === 'coverImage') {
        return 'Cover Image';
      } else {
        return 'Unknown File Type';
      }
    },
    showCheck(check) {
      // Open the check page in a new tab
      window.open('/check/' + check.check.hash + "?lang=de", '_blank');
    },
    informCheckReady(check) {
      // Prepare the request payload
      this.isSuccess = false;
      this.isVisible = false;
      this.isError = false;
      this.notificationMessage = "";

      const payload = {
        emailTo: this.checks.customer.email,
      };

      // Send a POST request to the server to send the email
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/checks/informCheckReady/' + check.check.id + '?lang=' + this.selectedLanguage, payload)
          .then(response => {
            // Show success notification message and reset form
            this.notificationMessage = response.data;
            this.isSuccess = true;
            this.isVisible = true;
            this.fetchChecks();
          })
          .catch(error => {
            // Show error notification message
            this.notificationMessage = error.response.data;
            this.isError = true;
            this.isVisible = true; // Set isVisible to true before showing the notification box
          });
    },

    createCheck(customer) {
      if (customer) {
        // Send a POST request to the server to send the email
        axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/customers/checks/' + customer.id)
            .then(response => {
              // Show success notification message and reset form
              this.notificationMessage = response.data;
              this.isSuccess = true;
              //this.resetForm();
              this.isVisible = true;
              this.fetchChecks();
            })
            .catch(error => {
              // Show error notification message
              this.notificationMessage = error.response.data;
              this.isError = true;
              this.isVisible = true; // Set isVisible to true before showing the notification box
            });
      }
    },
    extractDomain(email) {
      if (email) {
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
          return email.slice(atIndex + 1);
        }
        return email;
      }
      return "";

    },
    deleteCheck(check) {
      // Display a confirmation dialog before deleting the customer
      if (confirm(this.$t('deleteCheckMessage'))) {
        // Make an API request to delete the customer
        axios.delete(process.env.VUE_APP_SERVER_URL + '/api/v1/customers/checks/' + check.check.id)
            .then(response => {
              this.notificationMessage = response.data;
              this.isSuccess = true;
              this.fetchChecks();
            })
            .catch(error => {
              this.notificationMessage = error.response.data;
              this.isError = true;
            });
      }
    },
  },
  computed: {
    ...
        mapState(['selectedLanguage','customerEmail']),

    // isLoggedIn() {
    //   return sessionStorage.getItem('authToken') !== null;
    // }
  }
}
</script>

<style>
.add-check-button {
  border-radius: 0 !important;
  margin-bottom: 6px !important;
}

.container-fluid {
  max-width: 1200px;
  margin: 0 auto;
}
</style>