// ./locales/en.js
export default {
    hello: "Hello",
    goodbye: "Goodbye",
    emailPlaceholder: "Enter your business email address",
    sendButton: "Test me!",
    invalidEmailError: "Please enter a valid email address.",
    company: "My Company",
    customerOverview: "CheckFix Overview",
    allRightsReserved: "All rights reserved.",
    error: "Error!",
    success: "Success!",
    customerHackTest: "CheckFix Hack-Test",
    customerStatusCheck: "CheckFix Status-Check",
    email: "Email",
    overallScore: "Overall Rating",
    todoOverview: "ToDos Overview by Category",
    downloadStatusCheck: "Download CheckFix Status-Check",
    downloadHackTest: "Download CheckFix Hack-Test",
    buyHackTest: "Buy CheckFix Hack-Test",
    sendEmail: "Send Email",
    subject: "Subject",
    content: "Content",
    send: "Send",
    checkType: "Check",
    checkShared: "Shared",
    perform: "Perform",
    customerList: "Customer List",
    userList: "User List",
    action: "Actions",
    id: "ID",
    uploadFiles: "Upload Files",
    showHackTest:    "  Show Hack-Test  ",
    showStatusCheck: "Show Status-Check",
    sendStatusCheck: "Send Status-Check",
    sendHackTest:    "  Send Hack-Test  ",
    save: "Save",
    close: "Close",
    cancel: "Cancel",
    upload: "Upload",
    setPaid: "Set as Paid",
    paymentMissing: "Purchase the Hack-Test",
    paymentInfo: "You can download the Hack-Test after purchasing either the one-time Hack-Test or the subscription (Hack-Test ABO). Once payment is received, you will receive an email and can download the Hack-Test. Immediately order the Hack-Test below!",
    paymentStatus: "Change Payment Status",
    paymentStatusInfo: "Change the payment status manually of the selected customer. It can either be a one-time payment, a ABO or no payment.",
    hackTest: 'Hack-Test',
    hackTestABO: 'Hack-Test ABO',
    oneTimePayment: "One-Time",
    aboPayment: "ABO",
    noPayment: "No payment",
    welcomePageHackTest: "Thank you for choosing CheckFix and the Hack-Test.",
    welcomePageHackTestAbo: "Thank you for choosing CheckFix and the Hack-Test subscription.",
    welcomePageStatusCheck: "Thank you for choosing CheckFix and a Status-Check.",
    emailSent: "We have sent you an email. Please click on the link in the email to complete registration.",
    emailReceived: "No email found? Please check your spam folder.",
    inviteOthers: "Know someone who wants to become cyber-secure? Share your enthusiasm for CheckFix by entering their email address, and we will send an invitation.",
    inviterEmptyError: "Please enter a valid email address.",
    forgotDashboardLinkText: "Enter the email address with which your organization/company is registered. We will then send you access to your dashboard by email.",
    invite: "Invite",
    emailPlaceholderInvite: "Enter the address you want to invite!",
    contact: "For questions or problems, contact us at: ",
    support_email: "support@checkfix.io",
    registerPageStatusCheck: "2 steps to your company's Status-Check",
    registerPageHackTest: "2 steps to your company's Hack-Test",
    registerPageHackTestAbo: "2 steps to your company's Hack-Test ABO",
    verificationStart: "Your email address is geting verified.",
    verificationText: "You have successfully verified your email address. CheckFix will inform you once the check is complete.",
    verificationTextError: "Error during verification of the email address",
    addDomainsText: "Add the domains which you want to audit additionally. You can add 2 more domains. In the Hack-Test all domains will be shown together.\n\nPlease enter the domain name without 'https://www.' or 'https://'. Just the name (e.g.: example.com)!",
    title: "Title",
    domainLimitReached: "You can add a maximum of 2 domains.",
    addCustomer: "Add customer",
    addDomains: "Domain name to audit additionally",
    performCheck: "Perform CheckFix",
    performHackTest: "Perform Hack-Test",
    performStatusCheck: "Perform Status-Check",
    performHackTestMessage: "Are you sure you want to perform the Hack-Test?",
    performStatusCheckMessage: "Are you sure you want to perform the Status-Check?",
    performHackTestInfoMessage: "The Hack-Test will be performed for the selected customer. Each Hack-Test will be charged separately, you will get the invoice by email.",
    add: "Add",
    editDomains: "Edit verified domains",
    addUser: "Add user",
    language: "Language",
    deleteCustomer: "Delete customer",
    checkSent: "Check sent?",
    checktCreated: "Check created",
    customers: "Customers",
    users: "Users",
    name: "Name",
    uploadStatusCheck: "Upload CheckFix Status-Check",
    uploadHackTest: "Upload CheckFix Hack-Test",
    uploadPhoto: "Upload Photo",
    selectFileType: "Select File Type",
    selectFile: "Select File",
    uploadFile: "Upload File",
    uploadedFiles: "Uploaded Files",
    selectImageType: "Select Image Type",
    selectImage: "Select Image",
    logs: "Logs",
    date: "Date",
    fileList: "Files",
    addCheck: "Create check",
    days: "Day(s)",
    deleteCheckMessage: "Are you sure you want to delete this check?",
    deleteMessageUser: "Are you sure you want to delete this user?",
    deleteMessageCustomer: "Are you sure you want to delete this customer?",
    pageNotFoundTitle: "404 - Page Not Found",
    pageNotFoundDescription: "Sorry, the page you are looking for does not exist.",
    pageNotFoundReturnLink: "Go back to Home",
    invalidUsernameOrPassword: "Invalid username or api key",
    unauthorized: "Not authorised to view this section"
}
