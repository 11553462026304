<template>
  <div class="container-fluid mx-auto">
    <h1 v-if="this.checks && this.checks.customer" class="row d-flex justify-content-center">{{ $t('fileList') }}
      {{ extractDomain(this.checks.customer.email) }}</h1>
    <button class="btn btn-primary btn-sm float-right portal-button"
            @click="backToPortal()"
            v-html="$t('backToPortal')"></button>

    <div class="table-responsive table-responsive-sm table-responsive-md">
      <table class="table">
        <thead>
        <tr>
          <th v-html="$t('date')"></th>
          <th v-html="$t('checkType')"></th>
          <th v-html="$t('checkShared')"></th>
          <th v-html="$t('action')"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="check in this.checks.checkData" :key="check.id">
          <td>{{ formatTimestamp(check.check.createdTimestamp) }}</td>
          <td>
            <span v-if="check.hasHackTest && (this.checks.customer.paymentStatus === 1 || this.checks.customer.paymentStatus === 2)">Hack-Test</span>
            <span v-else>Status-Check</span>
          </td>
          <td>
            <span v-if="check.check.sent">&#x2714;</span>
            <span v-else>&#x2718;</span>
          </td>
          <td>
            <div class="button-container">
              <div class="d-block d-flex d-flex-wrap justify-content-center">
                <button class="btn btn-primary btn-block btn-sm mt-0 mr-1 button-small-height"
                        @click="showCheck(check)"><span>{{ this.$t('show') }}</span></button>
                <button class="btn btn-primary btn-block btn-sm mt-0 mr-1 button-small-height"
                        @click="informCheckReady(check)"><span>{{ this.$t('share') }}</span></button>
                <button class="btn btn-danger btn-block btn-sm mt-0 button-small-height icon-button"
                        @click="deleteCheck(check)"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <notification-box
      :message="notificationMessage"
      :is-success="isSuccess"
      :is-error="isError"
      :duration="5000"
      :is-visible="isVisible"
  />

</template>

<script>
import axios from 'axios';
import NotificationBox from "@/components/NotificationBox.vue";
import {mapState} from "vuex";
import moment from 'moment';

export default {
  components: {
    NotificationBox,
  },
  data() {
    return {
      checks: [],
      selectedCheck: null,
      notificationMessage: '',
      isSuccess: false,
      isError: false,
      isVisible: false,
      showSendEmail: false,
      customerId: '',
    };
  },
  created() {
    this.customerId = this.$route.params.customerId;
    let lang = this.$route.query.lang || this.selectedLanguage;
    if ((lang === '' || lang.length === 0 || lang === 'undefined') && (lang !== 'de' || lang !== 'en')) {
      lang = 'de';
    }
    this.$i18n.locale = lang;

    this.fetchChecks();
  },
  methods: {
    backToPortal() {
      this.$router.push("/portal");
    },
    fetchChecks() {
      const url = process.env.VUE_APP_SERVER_URL + '/api/v1/customers/checks/' + this.customerId;
      axios.get(url)
          .then((response) => {
            this.checks = response.data;
          }).catch(error => {
        // Show error notification message
        this.notificationMessage = error.response.data;
        this.isError = true;
        this.isVisible = true; // Set isVisible to true before showing the notification box
        this.showAddUser = false;
      });
    },
    formatTimestamp(timestamp) {
      return moment(timestamp).format('DD.MM.YYYY HH:mm');
    },
    showCheck(check) {
      // Open the check page in a new tab
      window.open('/check/' + check.check.hash + "?lang=de", '_blank');
    },
    informCheckReady(check) {
      // Prepare the request payload
      this.isSuccess = false;
      this.isVisible = false;
      this.isError = false;
      this.notificationMessage = "";

      // Send a POST request to the server to send the email
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/v1/checks/informCheckReady/' + check.check.id + '?lang=' + this.selectedLanguage)
          .then(response => {
            // Show success notification message and reset form
            this.notificationMessage = response.data;
            this.isSuccess = true;
            this.isVisible = true;
            this.fetchChecks();
          })
          .catch(error => {
            // Show error notification message
            this.notificationMessage = error.response.data;
            this.isError = true;
            this.isVisible = true; // Set isVisible to true before showing the notification box
          });
    },
    extractDomain(email) {
      if (email) {
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
          return email.slice(atIndex + 1);
        }
        return email;
      }
      return "";

    },
    deleteCheck(check) {
      // Display a confirmation dialog before deleting the customer
      if (confirm(this.$t('deleteCheckMessage'))) {
        // Make an API request to delete the customer
        axios.delete(process.env.VUE_APP_SERVER_URL + '/api/v1/customers/checks/' + check.check.id)
            .then(response => {
              this.notificationMessage = response.data;
              this.isSuccess = true;
              this.fetchChecks();
            })
            .catch(error => {
              this.notificationMessage = error.response.data;
              this.isError = true;
            });
      }
    },
  },
  computed: {
    ...
        mapState(['selectedLanguage', 'customerEmail']),
    // isLoggedIn() {
    //   return sessionStorage.getItem('authToken') !== null;
    // }
  }
}
</script>


<style>
.portal-button {
  border-radius: 0 !important;
  margin-bottom: 6px !important;
}
</style>